<template>
  <div class="home">
    <NewsTable />
  </div>
</template>

<script>
// @ is an alias to /src
import NewsTable from '@/components/NewsTable.vue';

export default {
  name: 'HomeView',
  components: {
    NewsTable
  }
}
</script>
