<template>
  <div class="service-table">
    <el-button
      type="primary"
      @click="openDialog(null)"
      style="float: right"
      class="add-service-btn"
      >+ Thêm bài viết</el-button
    >
    <el-table :data="services" style="width: 100%">
      <el-table-column prop="id" label="ID"  width="100"></el-table-column>
      <el-table-column prop="title" label="Tiêu đề" width="300"></el-table-column>
      <el-table-column prop="description" label="Mô tả"></el-table-column>
      <el-table-column prop="category.name" label="Danh mục"  width="300"></el-table-column>
      <el-table-column prop="createdOn" label="Ngày tạo"  width="150">
        <template #default="scope">
          {{ formatDate(scope.row.createdOn) }}
        </template>
      </el-table-column>
      <el-table-column label="Actions"  width="300">
        <template slot-scope="scope">
          <el-button size="mini" @click="openDialog(scope.row)">Sửa</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="deleteService(scope.row.id)"
            >Xóa</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="80%" :title="dialogTitle" :visible.sync="dialogVisible">
      <MyEditor :post="editedItem" @post-saved="handlePostSaved"/>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import MyEditor from "./MyEditor.vue";

const BASE_URL = 'https://www.ranghanhphuc.com/';

export default {
  name: "News",
  components: {
    MyEditor,
  },
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      services: [],
      editedIndex: -1,
      editedItem: {
        id: null,
        name: "",
        code: "",
      },
    };
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    openDialog(item) {
      this.dialogTitle = item ? "Edit Service" : "Add Service";
      this.editedIndex = this.services.indexOf(item);
      this.editedItem = item ? { ...item } : { ...this.defaultItem };
      this.dialogVisible = true;
    },
    saveService() {
      if (this.editedIndex > -1) {
        // Call API to update service
        axios
          .put(`${BASE_URL}api/v1/news`, this.editedItem)
          .then((response) => {
            console.log(response);
            this.dialogVisible = false; // Close dialog
            this.fetchServices(); // Reload data
          })
          .catch((error) => {
            console.error("Error updating service:", error);
          });
      } else {
        // Call API to create new service
        axios
          .post("api/v1/news", this.editedItem)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.services.push(this.editedItem);
            this.dialogVisible = false; // Close dialog
            this.fetchServices(); // Reload data
          })
          .catch((error) => {
            console.error("Error creating service:", error);
          });
      }
    },
    deleteService(id) {
      // Call API to delete service
      axios
        .delete(`${BASE_URL}api/v1/news/${id}`)
        .then((response) => {
          console.log(response);
          this.services = this.services.filter((service) => service.id !== id);
          this.fetchServices(); // Reload data
        })
        .catch((error) => {
          console.error("Error deleting service:", error);
        });
    },
    fetchServices() {
      const param = {
        page: 1,
      };
      axios
        .post(`${BASE_URL}api/v1/news/admin-news-list`, param)
        .then((response) => {
          console.log(response);
          this.services = response.data.data.data;
        })
        .catch((error) => {
          console.error("Error fetching services:", error);
        });
    },

    formatDate(date) {
      return dayjs(date).format("DD-MM-YYYY HH:mm:ss");
    },

    handlePostSaved() {
      this.dialogVisible = false; // Close the editor popup
      this.fetchServices(); // Fetch the updated list of posts
    },
  },
};
</script>

<style scoped>
.service-table {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-service-btn {
  margin-bottom: 20px;
}

.el-table {
  border: 1px solid #ebeef5;
  border-radius: 8px;
  overflow: hidden;
}

.el-table th,
.el-table td {
  padding: 12px 16px;
}

.el-table th {
  background-color: #fafafa;
  text-align: left;
}

.el-dialog {
  border-radius: 8px;
}

.service-form .el-form-item {
  margin-bottom: 16px;
}

.dialog-footer {
  text-align: right;
  padding: 10px 16px;
}

.dialog-footer .el-button {
  margin-left: 8px;
}

.service-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-top: 10px;
}
</style>
