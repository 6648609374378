<template>
  <div id="app">
    <div v-if="isAuthenticated">
      <!-- Navigation Bar -->
      <nav>
        <router-link to="/home">Home</router-link>
        <router-link to="/profile">Profile</router-link>
        <router-link to="/settings">Settings</router-link>
        <button @click="logout">Logout</button>
      </nav>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    isAuthenticated() {
      return !!localStorage.getItem('authToken');  // Check if token exists
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('authToken');
      this.$router.push('/login');  // Redirect to login page after logout
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
