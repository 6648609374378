<template>
    <div>
        <h1 style="text-align: center;">Bài viết</h1>
        <div style="margin: 0px 50px">
            <div style="margin-top: 10px; display: flex; text-align: center;">
                <div style="width: 100px;">Title:</div>
                <textarea v-model="title" style="width: 300px; border-radius: 10px;"></textarea>
            </div>
            <div style="margin-top: 10px; display: flex; text-align: center;">
                <div style="width: 100px;">Descriptions:</div>
                <textarea v-model="description" style="width: 300px; height: 100px; border-radius: 10px;"></textarea>
            </div>
            <div style="margin-top: 10px; display: flex; text-align: center;">
                <div style="width: 100px;">Category:</div>
                <el-select v-model="selectedCategory" placeholder="Select a category" style="width: 300px;">
                    <el-option v-for="category in categories" :key="category.id" :label="category.name"
                        :value="category.id" />
                </el-select>
            </div>
            <div style="margin-top: 10px; display: flex; text-align: center;">
                <div style="width: 150px;">Thumbnail Image:</div>
                <el-upload class="upload-demo" action="api/v1/news/upload"
                    :http-request="uploadImage"
                    :show-file-list="false"
                    :on-success="handleUploadSuccess"
                    :on-remove="handleRemove"
                    accept="image/*"
                    >
                    <el-button slot="trigger" size="small" type="primary">Select Image</el-button>
                </el-upload>
                <img style="height: 200px; object-fit: cover; margin-left: 15px" :src="thumbnailImage" alt="Thumbnail Image" class="preview-image" v-if="thumbnailImage">
            </div>
            <div style="border: 1px solid #ccc; margin-top: 10px;">
                <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
                <Editor style="height: 400px; overflow-y: hidden;" :defaultConfig="editorConfig" v-model="html"
                    @onChange="onChange" @onCreated="onCreated" />
            </div>
            <div style="margin-top: 10px; text-align: center;">
                <button @click="saveHtml" :disabled="isLoading"
                    style="width: 150px; height: 40px; border-radius: 5px; border: none; background-color: blue; color: white; cursor: pointer;">
                    <span v-if="isLoading">Saving...</span>
                    <span v-else>Lưu bài viết</span>
                </button>
                <button
                    style="margin-left: 30px; width: 150px; height: 40px; border-radius: 5px; background-color: red;  border: none; color: white; cursor: pointer;">Xem
                    trước</button>
            </div>
        </div>
    </div>
</template>

<script>
import { i18nChangeLanguage } from '@wangeditor/editor';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { savePost, updatePost, fetchCategories, uploadImageAPI } from '../api'; // Import API methods

export default {
    name: 'MyEditor',
    components: { Editor, Toolbar },
    props: {
        post: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            editor: null,
            html: '',
            title: '',
            description: '',
            selectedCategory: null,
            thumbnailImage: '',
            categories: [], // List of categories
            toolbarConfig: {},
            editorConfig: {
                placeholder: 'Type here...',
                MENU_CONF: {
                    uploadImage: {
                        server: 'api/v1/news/upload',
                        fieldName: 'file',
                        maxFileSize: 10 * 1024 * 1024,
                        maxNumberOfFiles: 5,
                        allowedFileTypes: ['image/*'],
                        method: 'post',
                        onSuccess(file, res) {
                            console.log('Upload success', file, res);
                        },
                        onFailed(file, res) {
                            console.log('Upload failed', file, res);
                        },
                        onError(file, err, res) {
                            console.log('Upload error', file, err, res);
                        },
                        customInsert(res, insertFn) {
                            const imageUrl = res.url; // Get image URL from response
                            insertFn(imageUrl);
                        }
                    }
                },
                mode: 'simple',
            },
            isLoading: false
        };
    },
    watch: {
        post: {
            handler(newVal) {
                this.title = newVal.title || '';
                this.description = newVal.description || '';
                this.html = newVal.content || '';
                this.selectedCategory = newVal.category.id || null;
                this.thumbnailImage = newVal.targetLink || '';
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await fetchCategories();
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        uploadImage({ file }) {
            const formData = new FormData();
            formData.append('file', file);
            uploadImageAPI(formData)
                .then(response => {
                    this.thumbnailImage = response.data.url; // Set thumbnail URL
                })
                .catch(error => {
                    console.error('Error uploading image:', error);
                });
        },
        handleUploadSuccess(file) {
            this.thumbnailImage = URL.createObjectURL(file.raw);
        },
        handleRemove() {
            this.thumbnailImage = '';
        },
        onCreated(editor) {
            this.editor = Object.seal(editor);
        },
        onChange(editor) {
            console.log('onChange', editor.getHtml());
        },
        async saveHtml() {
            this.isLoading = true;
            try {
                const postData = {
                    title: this.title,
                    description: this.description,
                    content: this.html,
                    categoryId: this.selectedCategory, // Send selected category ID
                    targetLink: this.thumbnailImage, // Send thumbnail URL
                    isEventHighlight: false
                };
                if (this.post.id) {
                    postData.id = this.post.id;
                    const updatedPost = await updatePost(this.post.id, postData);
                    console.log('Post updated successfully:', updatedPost);
                } else {
                    const savedPost = await savePost(postData);
                    console.log('Post saved successfully:', savedPost);
                }
                this.$emit('post-saved')
                // Handle success after saving
            } catch (error) {
                console.error('Error saving post:', error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    mounted() {
        i18nChangeLanguage('en');
        this.fetchCategories(); // Fetch categories on component mount
    },
    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy();
    }
};
</script>


<style src="@wangeditor/editor/dist/css/style.css"></style>
