import axios from 'axios';

const BASE_URL = 'https://www.ranghanhphuc.com/';

export async function savePost(postData) {
  try {
    const response = await axios.post(`${BASE_URL}api/v1/news`, postData);
    return response.data;
  } catch (error) {
    console.error('Error saving post:', error);
    throw error;
  }
}

export async function updatePost(id, postData) {
  try {
    const response = await axios.put(`${BASE_URL}api/v1/news/${id}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error saving post:', error);
    throw error;
  }
}

export async function uploadImageAPI(formData) {
  return axios.post(`${BASE_URL}api/v1/news/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function fetchCategories() {
  return axios.get(`${BASE_URL}api/v1/category`);
}